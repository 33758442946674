<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogResultAnalysisAddFast"
        max-width="800px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add test result analysis (Tạo nhanh)</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogResultAnalysisAddFast = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Title"
                    v-model="title_input"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddResultAnalysis"
            >
              Add
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogResultAnalysisAddFast = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapGetters } from "vuex";

export default {
  name: "ResultAnalysisAddFast",
  props: {},
  data() {
    return {
      title_input: "",
    };
  },
  computed: {
    ...mapGetters("entryTestStore", ["test_result_analysis"]),
    is_call_api: {
      get() {
        return this.$store.state.entryTestStore.is_call_api;
      },
      set(value) {
        this.$store.commit("entryTestStore/setIsCallApi", value);
      },
    },
    dialogResultAnalysisAddFast: {
      get() {
        return this.$store.state.entryTestStore.dialogResultAnalysisAddFast;
      },
      set(value) {
        this.$store.commit(
          "entryTestStore/setDialogResultAnalysisAddFast",
          value
        );
      },
    },
    test_result_analysis_id: {
      get() {
        return this.$store.state.entryTestStore.test_result_analysis_id;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTestResultAnalysisId", value);
      },
    },
  },
  watch: {
    dialogResultAnalysisAddFast(val) {
      if (val) {
        this.title_input = "";
      }
    },
  },
  methods: {
    btnAddResultAnalysis() {
      if (this.title_input === null || this.title_input === "") {
        this.$toasted.error("Tên thang điểm không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let vm = this;
      let data = {
        title: this.title_input,
      };
      vm.is_call_api = true;
      ApiService.post("prep-app/test-result-analysis", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.test_result_analysis.push(res.data);
            vm.test_result_analysis_id = res.data.id;
            vm.is_call_api = false;
            vm.dialogResultAnalysisAddFast = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
