<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogAddTestFast"
        max-width="800px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Tạo bài test (Tạo nhanh)</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddTestFast = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Title"
                    v-model="title_input"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddTest"
            >
              Add
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAddTestFast = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";

export default {
  name: "AddTestFast",
  data() {
    return {
      title_input: "",
    };
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.entryTestStore.is_call_api;
      },
      set(value) {
        this.$store.commit("entryTestStore/setIsCallApi", value);
      },
    },
    dialogAddTestFast: {
      get() {
        return this.$store.state.entryTestStore.dialogAddTestFast;
      },
      set(value) {
        this.$store.commit("entryTestStore/setDialogAddTestFast", value);
      },
    },
    test_id: {
      get() {
        return this.$store.state.entryTestStore.test_id;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTestId", value);
      },
    },
    title_test: {
      get() {
        return this.$store.state.entryTestStore.title_test;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTitleTest", value);
      },
    },
    search_test_input: {
      get() {
        return this.$store.state.entryTestStore.search_test_input;
      },
      set(value) {
        this.$store.commit("entryTestStore/setSearchTestInput", value);
      },
    },
    test_id_selected: {
      get() {
        return this.$store.state.entryTestStore.test_id_selected;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTestIdSelected", value);
      },
    },
  },
  methods: {
    btnAddTest() {
      if (this.title_input === null || this.title_input === "") {
        this.$toasted.error("Tên thang điểm không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let vm = this;
      let data = {
        test_title: this.title_input,
        test_description: "",
        is_draft: 0,
        time_restriction: 0,
        duration: 0,
        type_expand: 0,
      };
      vm.is_call_api = true;
      ApiService.post("prep-app/test/draft", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.title_test = res.data.title;
            vm.search_test_input = res.data.title;
            vm.test_id_selected = res.data.id;
            // vm.test_id = res.data.id;
            vm.is_call_api = false;
            vm.dialogAddTestFast = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
