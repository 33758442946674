var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('loading',{attrs:{"active":_vm.is_call_api},on:{"update:active":function($event){_vm.is_call_api=$event}}}),_c('div',{staticStyle:{"background-color":"rgb(243 246 249)"}},[_c('h1',{staticClass:"mt-5 mb-5",staticStyle:{"color":"#3f4254"}},[_vm._v("Entry test management")])]),_c('div',{staticClass:"pt-5 pl-5 pr-5"},[(_vm.canAccess('entry-test-create'))?_c('v-btn',{staticClass:"mb-5",attrs:{"rounded":"","color":"primary","x-small":"","large":""},on:{"click":_vm.openDialogEntryTest}},[_c('v-icon',{attrs:{"color":"white","dark":""}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"color":"white"}},[_vm._v("Thêm Entry Test")])],1):_vm._e(),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table datatable-bordered table-head-custom table-vertical-center table-head-bg"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Mục đích")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Thang nhận xét")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Test")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Action")])])]),_c('tbody',[_vm._l((_vm.entry_tests),function(entry_test,i){return [_c('tr',{key:i},[_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(i + 1))]),_c('td',[_c('p',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(entry_test.title)+" ")])]),_c('td',[_c('p',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(entry_test.purpose)+" ")])]),_c('td',[_c('p',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(entry_test.resultAnalysis.title)+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{attrs:{"to":{
                              name: 'EditTest',
                              params: { id: entry_test.test.id },
                            },"target":"_blank"}},[_c('p',_vm._g(_vm._b({staticClass:"font-weight-bolder d-block font-size-lg blue--text darken-2 text-decoration-underline"},'p',attrs,false),on),[_vm._v(" "+_vm._s(entry_test.test.title)+" ")])])]}}],null,true)},[_c('span',[_vm._v("Chi tiết và chỉnh sửa bài test")])])],1),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.canAccess('entry-test-update'))?_c('button',_vm._g(_vm._b({staticClass:"btn btn-icon btn-light-warning btn-sm ml-4",on:{"click":function($event){return _vm.editEntryTest(i, entry_test.id)}}},'button',attrs,false),on),[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-square-edit-outline")])],1)]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.canAccess('entry-test-view'))?_c('router-link',{attrs:{"to":{
                                name: 'GeneralFeedback',
                                params: { id: entry_test.resultAnalysis.id },
                              },"target":"_blank"}},[_c('button',_vm._g(_vm._b({staticClass:"btn btn-icon btn-light-success btn-sm ml-2"},'button',attrs,false),on),[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-clipboard-list")])],1)])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Tổng quan")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.canAccess('entry-test-view'))?_c('router-link',{attrs:{"to":{
                                name: 'DetailFeedback',
                                params: { id: entry_test.resultAnalysis.id },
                              },"target":"_blank"}},[_c('button',_vm._g(_vm._b({staticClass:"btn btn-icon btn-light-primary btn-sm ml-2"},'button',attrs,false),on),[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-eye-settings")])],1)])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Chi tiết")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.canAccess('entry-test-delete'))?_c('button',_vm._g(_vm._b({staticClass:"btn btn-icon btn-light-danger btn-sm ml-4",on:{"click":function($event){return _vm.deleteEntryTest(entry_test.id)}}},'button',attrs,false),on),[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-delete")])],1)]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])])]}),(!_vm.entry_tests.length)?[_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"6"}},[_vm._v("No data")])])]:_vm._e()],2)])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-pagination',{staticStyle:{"float":"left"},attrs:{"color":"#00BCD4","length":_vm.data_pagination.total_pages,"total-visible":6},model:{value:(_vm.data_pagination.current_page),callback:function ($$v) {_vm.$set(_vm.data_pagination, "current_page", $$v)},expression:"data_pagination.current_page"}})],1)],1)],1)],1),_c('EntryTestAdd',{attrs:{"entry_test_id":_vm.entry_test_id,"entry_test":_vm.entry_test},on:{"reloadEntryTestList":function($event){return _vm.getAllEntryTest()}}}),_c('ResultAnalysisAddFast'),_c('AddTestFast')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }