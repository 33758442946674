<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Entry test management</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDialogEntryTest"
        v-if="canAccess('entry-test-create')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Entry Test</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mục đích</th>
                    <th scope="col">Thang nhận xét</th>
                    <th scope="col">Test</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(entry_test, i) in entry_tests">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ entry_test.title }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ entry_test.purpose }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ entry_test.resultAnalysis.title }}
                        </p>
                      </td>
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <router-link
                              :to="{
                                name: 'EditTest',
                                params: { id: entry_test.test.id },
                              }"
                              target="_blank"
                            >
                              <p
                                v-bind="attrs"
                                v-on="on"
                                class="font-weight-bolder d-block font-size-lg blue--text darken-2 text-decoration-underline"
                              >
                                {{ entry_test.test.title }}
                              </p>
                            </router-link>
                          </template>
                          <span>Chi tiết và chỉnh sửa bài test</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="editEntryTest(i, entry_test.id)"
                                v-if="canAccess('entry-test-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'GeneralFeedback',
                                  params: { id: entry_test.resultAnalysis.id },
                                }"
                                target="_blank"
                                v-if="canAccess('entry-test-view')"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-clipboard-list</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Tổng quan</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'DetailFeedback',
                                  params: { id: entry_test.resultAnalysis.id },
                                }"
                                target="_blank"
                                v-if="canAccess('entry-test-view')"
                              >
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-eye-settings</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Chi tiết</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteEntryTest(entry_test.id)"
                                v-if="canAccess('entry-test-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!entry_tests.length">
                    <tr>
                      <td style="text-align: center" colspan="6">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <EntryTestAdd
      :entry_test_id="entry_test_id"
      :entry_test="entry_test"
      @reloadEntryTestList="getAllEntryTest()"
    />
    <ResultAnalysisAddFast />
    <AddTestFast></AddTestFast>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import ResultAnalysisAddFast from "@/view/pages/entry-test/modal/ResultAnalysisAddFast";
import AddTestFast from "@/view/pages/entry-test/modal/AddTestFast";
import Swal from "sweetalert2";
export default {
  name: "EntryTestList",
  components: {
    AddTestFast,
    ResultAnalysisAddFast,
    Loading: () => import("vue-loading-overlay"),
    EntryTestAdd: () => import("./modal/EntryTestAdd"),
  },
  data() {
    return {
      entry_tests: [],
      // is_call_api: false,
      entry_test_id: null,
      entry_test: {},
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
    };
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.entryTestStore.is_call_api;
      },
      set(value) {
        this.$store.commit("entryTestStore/setIsCallApi", value);
      },
    },
    dialogEntryTest: {
      get() {
        return this.$store.state.entryTestStore.dialogEntryTest;
      },
      set(value) {
        this.$store.commit("entryTestStore/setDialogEntryTest", value);
      },
    },
    type_form_entry_test: {
      get() {
        return this.$store.state.entryTestStore.type_form_entry_test;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTypeFormEntryTest", value);
      },
    },
  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllEntryTest(page);
    },
  },
  created() {
    this.getAllEntryTest();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllEntryTest(page = 1) {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/entry-test?page=" + page);
        if (res.status === 200) {
          vm.entry_tests = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
      }
    },
    openDialogEntryTest() {
      this.type_form_entry_test = "add";
      this.dialogEntryTest = true;
    },
    editEntryTest(index, entry_test_id) {
      this.entry_test = this.entry_tests[index];
      this.entry_test_id = entry_test_id;
      this.type_form_entry_test = "update";
      this.dialogEntryTest = true;
    },
    deleteEntryTest(entry_test_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/entry-test/" + entry_test_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllEntryTest();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
